/* ==========================================================================
   Global image styles
   ========================================================================== */

   
.wp-block-quote {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    max-width: 431px;
    margin: _em(43) auto;
    text-align: center !important;

    p {
        @include font-alt();
        letter-spacing: -0.48px;
        font-size: _em(24);
        line-height: _em(34, 24);

        @include breakpoint(sm) {
            letter-spacing: -0.32px;
            font-size: _em(16);
            line-height: _em(24, 16);
        }

        &::before {
            content: url('../../assets/images/quotes--blue.svg');
            display: block;
            width: fit-content;
            height: fit-content;
            margin-right: auto;
            color: $color-blue;

            @include breakpoint(sm) {
                margin: 0 auto;
            }
        }
    }

    &.is-style-blue-quote {
        p:before {
            content: url('../../assets/images/quotes--blue.svg');
        } 
    }
    &.is-style-periwinkle-quote {
        p:before {
            content: url('../../assets/images/quotes--periwinkle.svg');
        } 
    }
    &.is-style-sun-quote {
        p:before {
            content: url('../../assets/images/quotes--sun.svg');
        } 
    }
    &.is-style-orange-quote {
        p:before {
            content: url('../../assets/images/quotes--orange.svg');
        } 
    }
    &.is-style-peach-quote {
        p:before {
            content: url('../../assets/images/quotes--peach.svg');
        } 
    }

    cite  {
        @include font-alt();
        width: 100%;
        font-size: _em(24);
        line-height: _em(34, 24);

        @include breakpoint(sm) {
            font-size: _em(16);
            line-height: _em(24, 16);
        }
    }

    &.has-text-align-right {
        text-align: right !important;
    }
    &.has-text-align-left {
        text-align: left !important;
    }
    &.has-text-align-center {
        text-align: center !important;
    }

    // Plain style...
    &.is-style-plain {
        max-width: 894px;
        -webkit-background-clip: text !important;
        padding: 0;
        -webkit-text-fill-color: transparent !important;

        p {
            @include font(bold);
            background: inherit;
            font-size: _em(42) !important;
            line-height: _em(50, 42) !important;

            @include breakpoint(sm) {
                font-size: _em(28) !important;
                line-height: _em(35, 28) !important;
            }

            &::before {
                display: none;
            }
        }
        
        cite {
            font-size: _em(16);
            line-height: _em(34, 16);
        }
    }

    // With an image...
    &:has(.wp-block-image) {
        p {
            position: absolute;
            top: 80px;
            left: 0;
            margin: 0;

            @include breakpoint(sm) {
                top: 0;
            }

            &::before {
                margin-right: auto;
    
                @include breakpoint(sm) {
                    margin-right: auto;
                    margin-left: 0;
                }
            }
        }

        .wp-block-image {
            margin-left: auto;
        }
    }
}