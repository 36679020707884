/* ==========================================================================
   Global Gutenberg colors style
   ========================================================================== */

// Colors
.has-white-background-color { background-color: $color-white !important; color: $color-black !important; }
.has-black-background-color { background-color: $color-black !important; color: $color-moon !important;}
.has-periwinkle-background-color { background-color: $color-periwinkle !important; color: $color-black !important; }
.has-peach-background-color { background-color: $color-peach !important; color: $color-black !important; }
.has-sun-background-color { background-color: $color-sun !important; color: $color-black !important; }
.has-blue-background-color { background-color: $color-blue !important; color: $color-black !important; }
.has-orange-background-color { background-color: $color-orange !important; color: $color-black !important; }
.has-cloud-pink-background-color { background-color: $color-cloud-pink !important; color: $color-black !important; }
.has-moon-background-color { background-color: $color-moon !important; color: $color-black !important; }
.has-midnight-background-color { background-color: $color-midnight !important; color: $color-moon !important; }

// Text colors
.has-white-color { color: $color-white !important;}
.has-black-color { color: $color-black !important; }
.has-periwinkle-color { color: $color-periwinkle !important; }
.has-peach-gray-color { color: $color-peach !important; }
.has-sun-gray-color { color: $color-sun !important; }
.has-blue-gray-color { color: $color-blue !important; }
.has-orange-gray-color { color: $color-orange !important; }
.has-cloud-pink-gray-color { color: $color-cloud-pink !important; }
.has-moon-color { color: $color-moon !important; }
.has-midnight-color { color: $color-midnight !important; }

// Gradients
.has-cloud-pink-to-periwinkle-01-gradient-background {
   background: linear-gradient(122.71deg, #E8DFD3 2.54%, #E0D9D5 28.49%, #B7BBFF 82.38%);
   color: $color-black !important;
}
.has-cloud-pink-to-periwinkle-02-gradient-background {
   background: linear-gradient(71.53deg, #B7BBFF 11.95%, #E8DFD3 136.92%);
   color: $color-black !important;
}
.has-peach-to-sun-01-gradient-background {
   background: linear-gradient(40.6deg, #FFA269 -17.17%, #FFDA48 63.67%);
   color: $color-black !important;
}
.has-peach-to-sun-02-gradient-background {
   background: linear-gradient(90deg, #F8A16B 0%, #FFDA48 100%);
   color: $color-black !important;
}
.has-peach-to-cloud-pink-gradient-background {
   background: linear-gradient(90deg, #FFA269 0%, #E8DFD3 67.37%);
   color: $color-black !important;
}
.has-periwinkle-to-peach-gradient-background {
   background: linear-gradient(204.69deg, #FFA269 41.99%, #C7B5DD 122.11%);
   color: $color-black !important;
}
.has-periwinkle-to-sun-gradient-background {
   background: linear-gradient(90deg, #B7BBFF 0%, #B7BBFF 37%, #FFDA48 100%);
   color: $color-black !important;
}
.has-orange-to-periwinkle-gradient-background {
   background: linear-gradient(90deg, #F47932 0%, #B7BBFF 35.52%);
   color: $color-black !important;
}