.single-resources {
    .template-post {

        .is-style-post-container {
            .container {
                display: flex;
            }

            .wp-block-media-text {
                margin: _em(200) 0;

                @include breakpoint(sm) {
                    margin: _em(30) 0;
                }

            }

            .wp-block-media-text__media {
                margin-bottom: _em(30);
                position: relative;
                
                @include breakpoint(sm) {
                    aspect-ratio: 1/1;
                    // height: _em(169);
                    img {
                        width: 100%;
                        object-fit: cover;
                        height: 100%;
                    }
                }
            }
        }
        .post-container__content {
            padding-left: 0;
            padding-right: _em(72);
            width: 50%;

            @include breakpoint(sm) {
                padding-right: 0;
                width: 100%;
            }
            
            .wp-block-media-text {
                display: block;
                .wp-block-media-text__content {
                    padding: 0;
                }

                .wp-block-media-text__media {
                    display: none;

                    @include breakpoint(sm) {
                        display: block;
                    }
                }
            }
        }


        .post-container__featured-image {
            height: auto;
            max-height: 100%;
            margin-left: _em(72);
            position: relative;
            width: calc(50% - #{_em(72)});
            // transition: background-color 0.2s linear;

            @include breakpoint(sm) {
                display: none;
            }

            .resource-sidebar__image {
                top: 17%;
                // top: 25%;
                // transform: translateY(-50%);
                position: sticky;
                display: none;
                z-index: 2;

                &.active {
                    display: block;
                }
            }

            .post-container__featured-image-background {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                // transition: background-color 0.2s linear;
                width: 100%;
                z-index: 1;

                @include breakpoint(sm) {
                    display: none;
                }
            }
        }
    }
}