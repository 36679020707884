/* ==========================================================================
   Global button styles
   ========================================================================== */

.button {
	@include fill-button();
}

.wp-block-buttons {
   display: flex;
   flex-wrap: wrap;
   margin-top: 50px;
   gap: 30px;

   .wp-block-column & {
      @include breakpoint(gutenberg) {
         margin-top: 50px !important;
      }
   }

   &:first-child {
      margin-top: 0;
   }

   @include breakpoint(gutenberg) {
      &.is-content-justification-right {
         justify-content: flex-start !important;
      }
   }
}

.wp-block-button {

   // Default
   .wp-block-button__link {
      @include fill-button();
      white-space: nowrap;

      &.has-sun-background-color {
         @include fill-button('sun');
      }
      &.has-periwinkle-background-color {
         @include fill-button('periwinkle');
      }
      &.has-orange-background-color {
         @include fill-button('orange');
      }
      &.has-sun-background-color {
         @include fill-button('peach');
      }
   }

   // Outline
   &.is-style-outline {
      .wp-block-button__link {
         @include outline-button();
      }
   }

   // Underline
   &.is-style-underline {
      .wp-block-button__link {
         @include underline-button();
      }
   }
}
