.wysiwyg-content {

	> :first-child {
		margin-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
	}

	h1 {}

	h2 {}

	h3 {}

	h4 {}

	li {}

	img {
		height: auto;
		max-width: 100%;
	}

	.button {
		margin: 1em 0;
	}

	.button+.button {
		margin-left: 1em;
	}

	.alignright {
		float: right;
		margin: 0 0 _em(40) _em(40);
		max-width: 50%;
	}

	.alignleft {
		float: left;
		margin: 0 _em(40) _em(40) 0;
		max-width: 50%;
	}

	@include breakpoint(xs) {

		.alignright,
		.alignleft {
			float: none;
			margin: 0;
			max-width: 100%;
			width: auto;
		}
	}

	table {
		border: 1px solid $color-primary;
		width: 100%;

		th,
		td {
			padding: _em(15) _em(30);
			text-align: left;
			vertical-align: top;
		}

		th {
			background-color: $color-primary;
			border-right: 1px solid #fff;
			color: #fff;
			white-space: nowrap;
		}

		td {}

		/* tr:nth-child(even) td {
            background-color: #ccc;
        } */
	}

	blockquote {}

	.citation {}

	.iframe-container {
		height: 0;
		margin-bottom: 1.4em;
		padding-top: 56.25%;
		position: relative;

		iframe {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

	}

}
