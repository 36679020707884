/* ==========================================================================
   Global heading styles
   ========================================================================== */

 
ul, ol {
    @include font(regular);
    letter-spacing: 0.36px;
    font-size: _em(18);
    line-height: _em(26, 18);

    @include breakpoint(sm) {
        letter-spacing: 0.32px;
        font-size: _em(16); 
        line-height: _em(24, 16);
    }

    li {
        margin-bottom: _em(20, 18);
    }

    &.has-small-font-size {
        letter-spacing: 0.32px;
        font-size: _em(16);
        line-height: _em(34, 16);

        li {
            margin-bottom: _em(20, 16);
        }
    }
    &.has-medium-font-size {
        letter-spacing: 0.48px;
        font-size: _em(24); 
        line-height: _em(34, 24);

        @include breakpoint(sm) {
            letter-spacing: 0.32px;
            font-size: _em(16); 
            line-height: _em(24, 16);

            li {
                margin-bottom: _em(20, 16);
            }
        }

        li {
            margin-bottom: _em(20, 24);
        }
    }

    &.has-pitch-medium-font-family {
        @include font-alt();
        letter-spacing: -0.54px;

        @include breakpoint(sm) {
            letter-spacing: -0.48px;
        }
        
        &.has-medium-font-size {
            letter-spacing: -0.72px;
        }
    }

    &.has-eina-regular-font-family {
        @include font(regular);
        letter-spacing: 0.36px;
        
        &.has-small-font-size {
            letter-spacing: 0.32px;
        }
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}