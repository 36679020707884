.page-section {
	margin: 80px 0;
	position: relative;
	z-index: 2;

	&--site-header {
		margin: 0;
	}

	&--site-footer {
		margin: 0;
	}

	&--spotlight-listing {
		margin: 0;
	}
}
