/* ==========================================================================
   Global image styles
   ========================================================================== */

   
.wp-block-image {
    margin: 0;

    &.is-style-card {
        position: relative;
        transform: rotate(5deg);
        width: 300px;
        height: 400px;
        clip-path: rect(0 100% 100% 0 round 10px);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        
        &::after {
            content: "";
            z-index: 1;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('../../assets/images/gradient--orange.png');
            background-size: cover;
        }
    }
}