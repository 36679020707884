.site-footer {
    background-color: $color-black;
    padding: _em(122) 0 _em(170);

    @include breakpoint(sm) {
        padding: _em(54) 0 _em(42);
    }

    .container {
        /* padding: 0 _em(55) 0 _em(48); */
        padding: 0;

        @include breakpoint(lg) {
            padding: 0 _em(20);
        }

        @include breakpoint(sm) {
            padding: 0 _em(20);
        }
    }

}

#menu-footer-navigation {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 8.57px;

    // @include breakpoint(sm) {
    // }

    li {
        margin-right: _em(20, 24);

        @include breakpoint(sm) {
            margin: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:nth-child(6n+1) a {
            @include outline-button('periwinkle');

            @include breakpoint(sm) {
                font-size: _em(12);
            }
        }
        &:nth-child(6n+2) a {
            @include outline-button('orange');

            @include breakpoint(sm) {
                font-size: _em(12);
            }
        }
        &:nth-child(6n+3) a {
            @include outline-button('sun');

            @include breakpoint(sm) {
                font-size: _em(12);
            }
        }
        &:nth-child(6n+4) a {
            @include outline-button('blue');

            @include breakpoint(sm) {
                font-size: _em(12);
            }
        }
        &:nth-child(6n+5) a {
            @include outline-button('moon');

            @include breakpoint(sm) {
                font-size: _em(12);
            }
        }
        &:nth-child(6n+6) a {
            @include outline-button('peach');

            @include breakpoint(sm) {
                font-size: _em(12);
            }
        }
    } 

}


.site-footer__utility-nav {
    display: flex;
    margin: _em(40) 0 _em(68);
    padding: 0;
    list-style: none;

    @include breakpoint(sm) {
        display: block;
    }

    li {
        @include font(bold);
        letter-spacing: 10%;
        text-transform: uppercase;
        font-size: _em(14);
        line-height: _em(16.41, 14);
        

        + li {
            margin-left: _em(40, 14);

            @include breakpoint(sm) {
                margin-top: _em(40);
                margin-left: 0;
            }
        }

        a {
            color: $color-orange;
        }
    }
}

.site-footer__bottom-content {
    display: flex;
    justify-content: space-between;

    @include breakpoint(sm) {
        flex-wrap: wrap;
    }
}

.site-footer__left-content {
    @include breakpoint(sm) {
        order: 2;
        width: 100%;
    }
}
.site-footer__right-content {
    @include breakpoint(sm) {
        order: 1;
        width: 100%;
    }
}

.site-footer__logo {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 20px;

    @include breakpoint(sm) {
        align-items: flex-start;
    }
    
    .logo__wrap {
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 10px;


        img {
            position: relative;
            bottom: 3px;
        }
    }

    p {
        margin: 0;
        color: $color-moon;
        text-align: right;
        font-size: _em(13);
        
        span {
            @include font(bold, italic);
        }
    }
}

.site-footer__socials {
    display: flex;
    align-items: center;
    margin: 0 0 _em(27);
    padding: 0;
    list-style: none;

    li + li {
        margin-left: _em(20);
    }

    a {
        color: $color-moon;
        font-size: _em(21);

        @include breakpoint(sm) {
            font-size: _em(28);
        }
    }
}

.site-footer__copyright {
    color: $color-moon;
    letter-spacing: 7%;
    font-size: _em(10);
    line-height: _em(13.8, 10);

    @include breakpoint(sm) {
        font-size: _em(16);
    }
}