.single-stories {
    &--spotlight {
        .is-style-post-container .container {
            display: flex;
            flex-direction: row-reverse;

            @include breakpoint(sm) {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding-top: _em(40);
            }
        }

        .post-container__featured-image,
        .post-container__content {
            
        }
    }
}

.post-container__content {
    padding: _em(139) _em(0) _em(100) _em(146);
    width: auto;
    flex: 1;

    @include breakpoint(lg) {
        padding: _em(139) _em(0) _em(100) _em(40);
    }
    @include breakpoint(sm) {
        order: 2;
        padding: _em(40) 0;
        width: 100%;
    }

    .wp-block-gallery {
        @include breakpoint(sm) {
            display: block;

            .wp-block-image {
                aspect-ratio: 1/1;
                width: 100% !important;

                +.wp-block-image {
                    margin-top: _em(25) !important;
                }
            }
        }
    }
}

.post-container__featured-image {
    height: auto;
    max-height: 100vh;
    opacity: 1;
    position: sticky;
    top: 0;
    transition: opacity 0.7s ease;
    width: 574px;

    @include breakpoint(lg) {
        width: _em(474);
    }

    @include breakpoint(md) {
        width: _em(350);
    }

    @include breakpoint(sm) {
        height: _em(525);
        order: 1;
        position: static;
        width: 100%;
        // max-width: 100%;
    }

    &.is-hidden {
        opacity: 0;
        @include breakpoint(sm) {
            opacity: 1;
        }
    }

    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}

.stories__featured-image {
    display: none;
}