/* ==========================================================================
   Global heading styles
   ========================================================================== */

 
p {
    @include font(regular);
    margin-bottom: _em(30, 18);
    letter-spacing: 0.36px;
    font-size: _em(18);
    line-height: _em(26, 18);
    
    @include breakpoint(sm) {
        letter-spacing: 0.32px;
        font-size: _em(16); 
        line-height: _em(24, 16);
    }

    &.has-small-font-size {
        letter-spacing: 0.32px;
        font-size: _em(16);
        line-height: _em(34, 16);
    }
    
    &.has-medium-font-size {
        letter-spacing: 0.48px;
        font-size: _em(24); 
        line-height: _em(34, 24);

        @include breakpoint(sm) {
            letter-spacing: 0.32px;
            font-size: _em(16); 
            line-height: _em(24, 16);
        }
    }

    &.has-pitch-medium-font-family {
        @include font-alt();
        letter-spacing: -0.54px;

        @include breakpoint(sm) {
            letter-spacing: -0.48px;
        }
        
        &.has-medium-font-size {
            letter-spacing: -0.72px;
        }
    }

    &.has-eina-regular-font-family {
        @include font(regular);
        letter-spacing: 0.36px;
        
        &.has-small-font-size {
            letter-spacing: 0.32px;
        }
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    
    // Eyebrow
    &.is-style-eyebrow {
        @include font-alt();
        margin-bottom: _em(20, 18);
        letter-spacing: 0;

        &.has-eina-regular-font-family {
            @include font(bold);
            margin-bottom: _em(20, 14);
            letter-spacing: 4.2px;
            // font-size: 14px !important;
            font-size: _em(14);
            line-height: 24px;

            &.has-medium-font-size {
                margin-bottom: _em(20, 24);
            }
            
            &.has-small-font-size {
                margin-bottom: _em(20, 16);
            }
        }

        &.has-medium-font-size {
            margin-bottom: _em(20, 24);
        }
        
        &.has-small-font-size {
            margin-bottom: _em(20, 16);
        }

        + h1, + h2, + h3, + h4, + h5, + h6 {
            margin-top: 0;
        }
    }


    // Background
    &.has-background {
        -webkit-background-clip: text !important;
        padding: 0 0 3px;
        -webkit-text-fill-color: transparent !important;
    }
}