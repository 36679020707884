/* ==========================================================================
   Global heading styles
   ========================================================================== */

   
h1 {
    @include font(bold);
    margin-bottom: _em(50, 42);
    letter-spacing: -0.42px;
    font-size: _em(42, 16);
    line-height: _em(50, 42);

    @include breakpoint(sm) {
        margin-bottom: _em(50, 30);
        letter-spacing: -0.30px;
        font-size: _em(30, 16);
        line-height: _em(35, 30);
    }

    + h1 {
        margin-top: -26px;
    }
    
    + p {
        margin-top: -30px;
    }
}

h2 {
    @include font(bold);
    margin-bottom: _em(50, 36);
    letter-spacing: -0.36px;
    font-size: _em(36, 16);
    line-height: _em(42, 36);

    @include breakpoint(sm) {
        margin-bottom: _em(50, 28);
        letter-spacing: -0.28px;
        font-size: _em(28, 16);
        line-height: _em(34, 28);
    }

    + p {
        margin-top: -30px;
    }
}

h3 {
    @include font(bold);
    margin-top: _em(60, 28);
    margin-bottom: _em(50, 28);
    letter-spacing: -0.56px;
    font-size: _em(28, 16);
    line-height: _em(36, 28);

    @include breakpoint(sm) {
        margin-bottom: _em(50, 26);
        letter-spacing: -0.52px;
        font-size: _em(26, 16);
        line-height: _em(32, 26);
    }

    + p {
        margin-top: -20px;
    }
}

h4 {
    @include font-alt();
    letter-spacing: -0.48px;
    font-size: _em(24, 16);
    line-height: _em(34, 24);

    @include breakpoint(sm) {
        margin-bottom: _em(50, 24);
        letter-spacing: -0.48px;
        font-size: _em(16, 16);
        line-height: _em(24, 16);
    }
}

h5 {
    @include font(semibold);
    letter-spacing: 0;
    font-size: _em(24, 16);
    line-height: _em(34, 24);
}

h6 {
    @include font-alt();
    letter-spacing: 0;
    font-size: _em(16, 16);
    line-height: _em(22, 36);
}


// - - - Margin - - -

h1, h2, h3, h4, h5, h6 {
    
    &:first-child {
        margin-top: 0;
    }    
    &:last-child {
        margin-bottom: 0;
    }
}


// - - - Color - - -

h1, h2, h3, h4, h5, h6 {
    color: $color-black;
    
    &.has-background {
        -webkit-background-clip: text !important;
        padding: 0 0 3px;
        -webkit-text-fill-color: transparent !important;
    }
}